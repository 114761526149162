import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { NavLink, Route } from "react-router-dom";

class MobileHeader extends React.Component {
  state = {
    drawerClass: "hiddenDropDownMenu",
    open: false,
  };

  openCloseDrawer() {
    if (this.state.open) {
      this.setState({ drawerClass: "hiddenDropDownMenu" });
      this.setState({ open: false });
    } else {
      this.setState({ drawerClass: "visibleDropDownMenu" });
      this.setState({ open: true });
    }
  }

  render() {
    //<img src="https://i.imgur.com/xD34X5o.png" style={{display: 'block'}} />
    // <Icon>watch_later</Icon>
    return (
      <div className="mobileHeader">
        <img
          alt="" src="/bdc_logo.png"
          className="logoImg"
          style={{ float: "left" }}
        />

        <Typography variant="h2" color="secondary">
          Bath Dental Centre
        </Typography>
        <Typography color="secondary" variant="subheading">
          "We love your smile"
        </Typography>

        <AppBar
          position="static"
          color="primary"
          onClick={() => {
            this.openCloseDrawer();
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={() => {
                this.openCloseDrawer();
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h6" color="inherit">
              <Route path="/home" render={() => "Home"} />
              <Route path="/team" render={() => "Team"} />
              <Route path="/services" render={() => "Services"} />
              <Route path="/contact_us" render={() => "Contact Us"} />
            </Typography>
          </Toolbar>
        </AppBar>
        <List className={this.state.drawerClass}>
          <NavLink
            className="mobileMenuTab"
            to="/home"
            onClick={() => {
              this.openCloseDrawer();
            }}
          >
            <ListItem button>
              <Icon className="smallSpace" color="secondary">
                home
              </Icon>
              <Typography variant="h7" color="secondary">
                Home
              </Typography>
            </ListItem>
          </NavLink>

          <Divider />
          <NavLink
            className="mobileMenuTab"
            to="/services"
            onClick={() => {
              this.openCloseDrawer();
            }}
          >
            <ListItem button>
              <Icon className="smallSpace" color="secondary">
                search
              </Icon>
              <Typography variant="h7" color="secondary">
                Services
              </Typography>
            </ListItem>
          </NavLink>

          <Divider />
          {/* <NavLink
            className="mobileMenuTab"
            to="/team"
            onClick={() => {
              this.openCloseDrawer();
            }}
          >
            <ListItem button>
              <Icon className="smallSpace" color="secondary">
                accessibility_new
              </Icon>
              <Typography variant="h7" color="secondary">
                Team
              </Typography>
            </ListItem>
          </NavLink> */}

          <Divider />
          <NavLink
            className="mobileMenuTab"
            to="/contact_us"
            onClick={() => {
              this.openCloseDrawer();
            }}
          >
            <ListItem button>
              <Icon className="smallSpace" color="secondary">
                mail
              </Icon>
              <Typography variant="h7" color="secondary">
                Contact us
              </Typography>
            </ListItem>
          </NavLink>

          <Divider />
        </List>
      </div>
    );
  }
}
export default MobileHeader;
