import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Helmet } from "react-helmet";
import Content from "./content.js";

class ContactUs extends React.Component {
  render() {
    return (
      <div>
        <Content>
          <Helmet>
            <title>Bath Dental: Contact Us</title>
            <meta name="description" content="Request an appointment" />
          </Helmet>

          <Typography variant="h4" gutterBottom="gutterBottom">
            Contact Bath Dental Centre
          </Typography>
          <Typography variant="subtitle1">
            To request an appointment, you can use the information provided
            below or call our office.
          </Typography>
          <Typography variant="overline">
            If you have an emergency please contact our office directly
            @613-352-5300.
          </Typography>
          <Divider className="dividerSpace" />
          <Grid
            container="container"
            direction="row"
            justafy="space-between"
            alignItems="flex-start"
          >
            <Grid item="item">
              <ContactInfo />
            </Grid>
            <Grid item="item">
              <RequestForm />
            </Grid>
          </Grid>
          <Divider className="dividerSpace" />
          <Map />
        </Content>
      </div>
    );
  }
}

class ContactInfo extends React.Component {
  render() {
    return (
      <div>
        <Grid
          direction="row"
          container="container"
          alignItems="center"
          className="gutterBottom"
        >
          <Icon>watch_later</Icon>
          <Typography variant="h4">Office Hours</Typography>
        </Grid>
        <div className="indent">
          <Typography variant="subtitle1">Monday 7:30am-4:30pm</Typography>
          <Typography variant="subtitle1">Tuesday 7:30am-4:30pm</Typography>
          <Typography variant="subtitle1">Wednesday 7:30am-4:30pm</Typography>
          <Typography variant="subtitle1">Thursday 7:30am-4:30pm</Typography>
        </div>
        <Grid
          direction="row"
          container="container"
          alignItems="center"
          className="gutterBottom"
        >
          <Icon>phone</Icon>
          <Typography variant="h4">Phone</Typography>
        </Grid>
        <div className="indent">
          <Typography variant="subtitle1">Office: (613) 352-5300</Typography>
        </div>
        <Grid
          direction="row"
          container="container"
          alignItems="center"
          className="gutterBottom"
        >
          <Icon>mail</Icon>
          <Typography variant="h4">Email</Typography>
        </Grid>
        <div className="indent">
          <Typography variant="subtitle1">
            bathdentalcentre@bellnet.ca
          </Typography>
        </div>
        <Grid
          direction="row"
          container="container"
          alignItems="center"
          className="gutterBottom"
        >
          <Icon>home</Icon>
          <Typography variant="h4">Location</Typography>
        </Grid>
        <div className="indent">
          <Typography variant="subtitle1">
            379 Queen Street, Bath, Ontario, K0H 1G0
          </Typography>
        </div>
      </div>
    );
  }
}

class RequestForm extends React.Component {
  state = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    time: "N/A",
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  onSubmit() {
    const data = this.state;
    alert("Appointment request sent!");

    console.log(data);
    fetch("/request", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  render() {
    return (
      <div>
        <div className="requestForm">
          <form onSubmit={this.handleSubmit}>
            <Grid item="item">
              <Grid item="item" justify="center" alignItems="center">
                <List>
                  <ListItem>
                    <Typography variant="h4">Request an appointment</Typography>
                  </ListItem>
                  <ListItem>
                    <TextField
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #ced4da",
                        "border-radius": "5px",
                      }}
                      variant="filled"
                      fullWidth="fullWidth"
                      name="name"
                      label="Name"
                      onChange={this.handleChange("name")}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #ced4da",
                        "border-radius": "5px",
                      }}
                      variant="filled"
                      fullWidth="fullWidth"
                      name="email"
                      label="Email"
                      onChange={this.handleChange("email")}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #ced4da",
                        "border-radius": "5px",
                      }}
                      variant="filled"
                      fullWidth="fullWidth"
                      name="phone"
                      label="Phone Number"
                      onChange={this.handleChange("phone")}
                    />
                  </ListItem>

                  <ListItem>
                    <FormGroup row="row">
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Monday"
                        onChange={this.handleCheck("monday")}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Tuesday"
                        value="monday"
                        onChange={this.handleCheck("tuesday")}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Wednesday"
                        onChange={this.handleCheck("wednesday")}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Thursday"
                        onChange={this.handleCheck("thursday")}
                      />
                    </FormGroup>
                  </ListItem>

                  <ListItem>
                    <div>
                      <FormControl>
                        <InputLabel>Time</InputLabel>
                        <Select
                          value={this.state.time}
                          onChange={this.handleChange("time")}
                          name="time"
                          inputProps={{
                            name: "time",
                          }}
                        >
                          <MenuItem value={"N/A"}>N/A</MenuItem>
                          <MenuItem value={"morning"}>Morning</MenuItem>
                          <MenuItem value={"afternoon"}>Afternoon</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </form>
        </div>
        <Grid container="container" justify="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={this.onSubmit.bind(this)}
            style={{
              backgroundColor: "#1769aa",
              position: "relative",
              top: "-17px",
              color: "white",
              "font-weight": "bold",
              "font-size": "20px",
            }}
          >
            Send
          </Button>
        </Grid>
      </div>
    );
  }
}

class Map extends React.Component {
  render() {
    return (
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=bath%20dental%20centre&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
          </iframe>
        </div>
      </div>
    );
  }
}

export default ContactUs;
