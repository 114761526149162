import Typography from "@material-ui/core/Typography";
import React from "react";
import { Slide } from "react-slideshow-image";
import "./bathdental_style.css";
import Content from "./content.js";

class Home extends React.Component {
  render() {
    var years = new Date(new Date() - new Date(1996, 9, 1)).getFullYear() - 1970;
    return (
      <div>
        <Content>
          <Typography variant="h4" gutterBottom="gutterBottom">
            Welcome to Bath Dental Centre: Celebrating {years} years!
          </Typography>
          <Typography variant="body1" gutterBottom="gutterBottom">
            We offer a full range of preventative and cosmetic procedures to
            ensure you maintain a bright and beautiful smile throughout your
            life.
          </Typography>
          <SlideShow />
        </Content>
      </div>
    );
  }
}


const slideImages = ["ss5.png", "ss9.png", "ss7.png", "ss8.png"];

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  arrows: false,
};

class SlideShow extends React.Component {
  render() {
    return (
      <Slide {...properties}>
        <div>
          <img class="slideshow_img" alt="" src={slideImages[0]} />
        </div>
        <div>
          <img class="slideshow_img" alt="" src={slideImages[1]} />
        </div>
        <div>
          <img class="slideshow_img" alt="" src={slideImages[2]} />
        </div>
        <div>
          <img class="slideshow_img" alt="" src={slideImages[3]} />
        </div>
      </Slide>
    );
  }
}

export default Home;
