import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import ContactUs from "./pages/contact_us.js";
import DesktopHeader from "./pages/desktopHeader.js";
import Footer from "./pages/footer.js";
import Home from "./pages/home.js";
import MobileHeader from "./pages/mobileHeader.js";
import Services from "./pages/services.js";
import Team from "./pages/team.js";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#1769aa",
    },
  },
});

class App extends Component {
  render() {
    let header;
    if (window.innerWidth > 1200) {
      header = <DesktopHeader />;
    } else {
      header = <DesktopHeader />;

//      header = <MobileHeader />;
    }

    return (
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <head>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
            <script
              src="https://www.google.com/recaptcha/api.js"
              async="async"
              defer="defer"
            ></script>
          </head>
          <div
            style={{
              backgroundColor: "#eee",
            }}
          >
            <Helmet>
              <title>Bath Dental Centre: Bath, Ontario</title>
              <meta
                name="description"
                content="Become a part of our family! See Dentist Dr. Yiling (Elaine) Xu and team members, Assistant (Joanna), Hygienist (Sarah) and Receptionist (Cindy) each time you visit. We strive to provide you with the highest quality of dental care in a relaxing, gentle and personalized manner. Our goal is to help you obtain and maintain the best oral health care while addressing YOUR dental needs."
              />
              <meta name="theme-color" content="#008f68" />
            </Helmet>

            {header}

            <Route
              exact="exact"
              path="/"
              render={() => <Redirect to="/home" />}
            />
            <Route path="/home" component={Home} />
            <Route path="/Team" component={Team} />
            <Route path="/services" component={Services} />
            <Route path="/contact_us" component={ContactUs} />
            <Footer />
          </div>
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;
