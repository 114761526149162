import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Helmet } from "react-helmet";
import "./bathdental_style.css";
import Content from "./content.js";

class Team extends React.Component {
  render() {
    return (
      <Content>
        <Helmet>
          <title>Bath Dental Centre: Team</title>
          <meta
            name="description"
            content="Come visit our friendly and professional staff."
          />
        </Helmet>

        <Typography variant="h4" gutterBottom="gutterBottom">
        Team Introduction
        </Typography>

        <Typography variant="subtitle1">
        We are proud to introduce our skilled and dedicated dental team! Our team of dental professionals is committed to providing the highest quality of care to our patients. We work together to provide comprehensive dental care in a friendly and welcoming environment. Our shared passion for dentistry ensures that our patients receive the best care possible.

        At Bath Dental Centre, we believe that our team's commitment to excellence and patient-centered care sets us apart from other dental practices. We strive to make your visit to our clinic a positive and enjoyable experience, and we look forward to providing you and your family with exceptional dental care.
        </Typography>

        <Divider className="dividerSpace" />

        <Grid container="container" wrap="nowrap">
          <Grid item="item">
            <Typography variant="h5" gutterBottom="gutterBottom">
              Dr. Elaine Xu - Dentist
            </Typography>
            <Typography zeroMinWidth="zeroMinWidth" variant="body1">
            After graduating from the University of Alberta's dental school, Dr. Elaine Xu returned to her hometown in Ottawa to start her career. There, she had the privilege of working with exceptional mentors and participated in numerous continuing education courses to enhance her skills. Her education includes a comprehensive implant course with TIDE, a surgical wisdom teeth removal course, and certification in Invisalign treatment.

            Dr. Xu is an active member of several professional organizations and has held leadership positions within them. She is a member of the Canadian Dental Association, Ontario Dental Association, Kingston District Dental Society, and Ottawa Dental Society. Additionally, she is a teaching faculty for Algonquin College's dental program.

            When not practicing dentistry, Dr. Xu enjoys reading and exploring new places, both in her physical travels and through literature. She is committed to providing her patients with exceptional dental care and improving their oral health. Building personal relationships with her patients is one of her passions, and she takes pride in getting to know each one of them.

            </Typography>
          </Grid>
          <Grid item="item">
            <img className="team_img" src="/yl.jpg" />
          </Grid>
        </Grid>

        <Divider className="dividerSpace" />

        <Grid container="container" wrap="nowrap">
          <Grid item="item">
            <Typography variant="h5" gutterBottom="gutterBottom">
              Sarah - Hygienist
            </Typography>
            <Typography zeroMinWidth="zeroMinWidth" variant="body1">
            Sarah was raised in a small agricultural community and subsequently relocated to pursue her career in dental hygiene at Georgian College. There she graduated with a Diploma of Dental Hygiene and has since become a registrant of the College of Dental Hygienists of Ontario. Additionally, she is a proud member of The Canadian Dental Hygienists Association, which reflects her unwavering commitment to her profession and staying up-to-date on the latest advances in the field. 

            As an invaluable member of the Bath Dental team, Sarah's empathetic approach is a perfect match for the practice's patient-centered philosophy. She takes great pride in being part of the Bath Dental team and relishes the opportunity to work alongside her colleagues in delivering the highest quality care to their patients. Sarah derives immense satisfaction from seeing her patients' radiant smiles and endeavours to provide them with unparalleled care while ensuring their utmost comfort. 

            Furthermore, Sarah takes immense pride in her furry companion, a large and plush "polar bear" like dog known as Toby. While not at the clinic, you can find her taking him for regular walks, hikes and swims.

            </Typography>
          </Grid>
          <Grid item="item">
            <img className="team_img" src="/Sarah.jpg" />
          </Grid>
        </Grid>

        <Divider className="dividerSpace" />

        <Grid container="container" wrap="nowrap">
          <Grid item="item">
            <Typography variant="h5" gutterBottom="gutterBottom">
              Cindy - Receptionist
            </Typography>
            <Typography zeroMinWidth="zeroMinWidth" variant="body1">
            Cindy was born and raised in the Graham Lake area before relocating to Elginburg in 2021. With over two decades of experience in the dental industry, Cindy has devoted her career to working as a dental receptionist. 

            Cindy's expertise in her position and her ability to listen to patients and address their concerns has made her a valuable member of the team. Cindy understands that dental visits can be stressful and anxiety-inducing for many patients, and she takes the time to listen to their needs and answer any questions they may have. She is patient and empathetic, and she strives to put patients at ease by creating a comfortable and welcoming environment. Her commitment to excellence and patient-centred care makes her an excellent addition to the team at Bath Dental, and she looks forward to delivering exceptional service to all patients.
            </Typography>
          </Grid>
          <Grid item="item">
            <img className="team_img" src="/Cindy.jpg" />
          </Grid>
        </Grid>

        <Divider className="dividerSpace" />

        <Grid container="container" wrap="nowrap">
          <Grid item="item">
            <Typography variant="h5" gutterBottom="gutterBottom">
              Joanna - Assistant
            </Typography>
            Joanna is a highly skilled and experienced dental assistant with over two decades of experience in the dental field. Patients often commend her for her gentle touch and ability to make them feel comfortable during procedures. She is proficient in all areas of dentistry and works closely with the team to ensure patients receive the highest level of care. Joanna's passion for dentistry and building relationships with her patients is evident in her commitment to helping them achieve optimal oral health. Her expertise and dedication to her profession make her an invaluable member of the Bath Dental team.

            In her spare time, Joanna enjoys renovating her home and gardening, further showcasing her eye for detail and creativity.
            <Typography zeroMinWidth="zeroMinWidth" variant="body1">
            </Typography>
          </Grid>
          <Grid item="item">
            <img className="team_img" src="/Joanna.jpg" />
          </Grid>
        </Grid>
      </Content>
    );
  }
}

export default Team;
