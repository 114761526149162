import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import DesktopMenu from "./desktopMenu.js";

class DesktopHeader extends React.Component {
  render() {
    let title_size = "40px";
    if (window.innerWidth > 1400) {
      title_size = "60px";
    }
    if (window.innerWidth > 1800) {
      title_size = "80px";
    }

    return (
      <div className="desktopHeader">
        <Grid
          container="container"
          direction="row"
          justify="space-between"
          alignItems="flex-end"
        >
          <Grid item="item">
            <Grid container="container">
              <Grid item="item">
                <img
                  alt="" src="/bdc_logo.png"
                  className="logoImg"
                />
              </Grid>
              <Grid item="item" justify="flex-end" alignItems="flex-end">
                <Typography
                  variant="display3"
                  color="secondary"
                  style={{
                    "font-size": title_size,
                  }}
                >
                  Bath Dental Centre
                </Typography>
                <br></br>
                <Typography color="secondary" variant="subheading">
                  "Your smile is our priority!"
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item="item" direction="column">
            <Grid
              container="container"
              direction="row"
              alignItems="center"
              justify="flex-end"
              style={{
                padding: "10px",
              }}
            >
              <Icon className="smallSpace">accessible</Icon>

              <Link
                to="/contact_us"
                style={{
                  "text-decoration": "none",
                }}
              >
                <Button
                  variant="contained"
                  className="smallSpace"
                  color="primary"
                >
                  Request An Appointment
                </Button>
              </Link>
            </Grid>

            <Grid
              container="container"
              direction="row"
              style={{
                padding: "10px",
              }}
            >
              <Icon>phone</Icon>
              <Typography className="smallSpace">613-352-5300</Typography>
              <Icon>mail</Icon>
              <Typography className="smallSpace">
                bathdentalcentre@bellnet.ca
              </Typography>
              <Icon>home</Icon>
              <Typography className="smallSpace">
                379 Queen Street, Bath, Ont
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <DesktopMenu />
      </div>
    );
  }
}
export default DesktopHeader;
