import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";

const footerStyle = {
  "background-color": "#4dabf5",
  padding: "20px",
};

class Footer extends React.Component {
  render() {
    return (
      <div color="primary" style={footerStyle}>
        <Grid
          container="container"
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Link
            to="/home"
            style={{
              "text-decoration": "none",
            }}
          >
            <Typography>Home</Typography>
          </Link>
          |
          <Link
            to="/services"
            style={{
              "text-decoration": "none",
            }}
          >
            <Typography>Services</Typography>
          </Link>
          |
          <Link
            to="/contact_us"
            style={{
              "text-decoration": "none",
            }}
          >
            <Typography>Contact Us</Typography>
          </Link>
        </Grid>
      </div>
    );
  }
}
export default Footer;
