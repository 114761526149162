import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React from "react";
import { Helmet } from "react-helmet";
import "./bathdental_style.css";
import Content from "./content.js";

class Services extends React.Component {
  render() {
    return (
      <div>
        <Content>
          <Helmet>
            <title>Bath Dental Centre: Services</title>
            <meta
              name="description"
              content="Our office will provide you and your family with complete general dental services to full mouth reconstruction. We work very well with the specialist in the area when required."
            />
          </Helmet>

          <img className="ContentFullImage" alt="" src="/services.jpg" />
          <Typography variant="h4" gutterBottom="gutterBottom">
            Our Services
          </Typography>
          <Typography variant="subtitle1">
            Our office will provide you and your family with complete general
            dental services to full mouth reconstruction. We work very well with
            the specialist in the area when required.
          </Typography>
          <Divider />
          <ServicesList />
          <Divider className="dividerSpace" />
          <Typography variant="h4" gutterBottom="gutterBottom">
            Dental Insurance
          </Typography>
          <Typography variant="body1">
            The extent of coverage has been established between your employer
            and the insurance company. Your insurance benefit is a contract
            between you and the insurance company. Our office is capable of
            transmitting insurance claims directly, via computer, to those
            insurance companies who are able to receive them. This reduces the
            time it takes for you to be reimbursed by your insurance. We will
            need your policy and certificate numbers at your initial visit in
            order to process your claims electronically. If you have any
            questions concerning your insurance coverage, a
            pretreatment/estimate can be sent to clarify your coverage. Please
            be aware that Dr. Krasna will make a diagnosis based on your dental
            needs regardless of insurance coverage. We will make every effort to
            provide you with a treatment plan that fits your schedule, budget,
            and gives you the best possible dental care. Payment may be made by:
            Cash, Personal cheque, Mastercard/Visa or interac and e transfer.
          </Typography>
        </Content>
      </div>
    );
  }
}

class ServicesList extends React.Component {
  render() {
    return (
      <div>
        <List>
          <ServicesItem title="Preventive">
            <List>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Scaling and root planning
                </Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Prophylaxis(polishing)
                </Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Fluoride treatments</Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Pit & fissure sealant
                </Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Oral Hygiene Instruction
                </Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Mouthguards</Typography>
              </ListItem>
            </List>
          </ServicesItem>

          <ServicesItem title="Orthodontic">
            <List>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Minor tooth movement
                </Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Space maintenance</Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Referral for advanced cases
                </Typography>
              </ListItem>
            </List>
          </ServicesItem>

          <ServicesItem title="Sedation">
            <List>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Nitrous Oxide sedation
                </Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Oral sedation</Typography>
              </ListItem>
            </List>
          </ServicesItem>

          <ServicesItem title="Palliative">
            <List>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Root canal treatment
                </Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Abscess</Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Extractions</Typography>
              </ListItem>
            </List>
          </ServicesItem>

          <ServicesItem title="Prosthetic">
            <List>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Implant retained dentures
                </Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Complete dentures</Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Partial dentures</Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Denture repairs/relines
                </Typography>
              </ListItem>
            </List>
          </ServicesItem>

          <ServicesItem title="Restorative">
            <List>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Composite("white") fillings
                </Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Amalgam ("silver") fillings
                </Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Crowns/bridges</Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Implant retained Crowns /Bridges
                </Typography>
              </ListItem>
            </List>
          </ServicesItem>

          <ServicesItem title="Diagnostic">
            <List>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Exam of head, neck</Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Oral cavity - x-rays
                </Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Halitosis</Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Oral cancer screening
                </Typography>
              </ListItem>
            </List>
          </ServicesItem>

          <ServicesItem title="Cosmetics">
            <List>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Porcelain veneers</Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">Cosmetic bonding</Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Custom whitening at home
                </Typography>
              </ListItem>
            </List>
          </ServicesItem>

          <ServicesItem title="Periodontal">
            <List>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Periodontal maintenance
                </Typography>
              </ListItem>
              <ListItem>
                <ArrowRight />
                <Typography variant="subtitle1">
                  Bruxism ("grinding") appliances
                </Typography>
              </ListItem>
            </List>
          </ServicesItem>
        </List>
      </div>
    );
  }
}
class ServicesItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collpase: false,
    };
  }

  handleClick = () => {
    this.setState({
      collpase: !this.state.collpase,
    });
  };

  render() {
    return (
      <div>
        <ListItem
          button="button"
          onClick={() => {
            this.handleClick();
          }}
        >
          {this.props.title}
          {this.state.collpase ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={this.state.collpase}
          timeout="auto"
          unmountOnExit="unmountOnExit"
        >
          {this.props.children}
        </Collapse>
      </div>
    );
  }
}

export default Services;
