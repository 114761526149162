import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { NavLink } from "react-router-dom";
import "./bathdental_style.css";

class DesktopMenu extends React.Component {
  state = {
    value: "home",
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    return (
      <div>
        <AppBar
          position="static"
          style={{
            "border-bottom": "4px solid blue",
          }}
        >
          <div className="centerConent">
            <Grid container="container" direction="row">
              <NavLink className="tab" activeClassName="activeTab" to="/home">
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    "font-weight": "bold",
                  }}
                >
                  Home
                </Typography>
              </NavLink>

              { <NavLink className="tab" activeClassName="activeTab" to="/team">
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    "font-weight": "bold",
                  }}
                >
                  Our Team
                </Typography>
              </NavLink>}

              <NavLink
                className="tab"
                activeClassName="activeTab"
                to="/services"
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    "font-weight": "bold",
                  }}
                >
                  Services
                </Typography>
              </NavLink>

              <NavLink
                className="tab"
                activeClassName="activeTab"
                to="/contact_us"
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    "font-weight": "bold",
                  }}
                >
                  Contact Us
                </Typography>
              </NavLink>
            </Grid>
          </div>
        </AppBar>
      </div>
    );
    // return (<div>
    //   <AppBar position="static">
    //     <Tabs className="centerConent" value={this.state.value} onChange={this.handleChange}>
    //       <Tab value="home" classes={{
    //           root: rootClass,
    //           selected: selectedClass
    //         }} label="Home" component={Link} to={"/home"}/>
    //       <Tab value="team" label="Team" component={Link} to={"/team"}/>
    //       <Tab value="services" label="Services" component={Link} to={"/services"}/>
    //       <Tab value="contact_us" label="Contact Us" component={Link} to={"/contact_us"}/>
    //     </Tabs>
    //   </AppBar>
    // </div>);
  }
}
export default DesktopMenu;
