import { Grid, Paper } from "@material-ui/core/";
import React from "react";
import "./bathdental_style.css";

class Content extends React.Component {
  render() {
    return (
      <div className="centerConent">
        <Grid container="container" justify="center" alignItems="center">
          <Paper
            style={{
              width: "1000px",
              maxWidth: "80vw",
              margin: "15px",
              padding: "15px",
            }}
          >
            {this.props.children}
          </Paper>
        </Grid>
      </div>
    );
  }
}

export default Content;
